import { EntitySelectors } from '@breathelife/insurance-entities';
import { AnswerPath, LocalizedQuestionnaire } from '@breathelife/questionnaire-engine';
import { QuestionnaireBlueprint } from '@breathelife/types';

import ApiService from './ApiService';

export type CarrierQuestionnaireResponse = {
  version: string;
  versionId: string | null;
  questionnaire: LocalizedQuestionnaire;
  blueprint?: QuestionnaireBlueprint;
  entitySelectors: EntitySelectors;
  nodeIdToAnswerPath?: [string, AnswerPath][];
};

export async function fetchCarrierQuestionnaire(applicationId: string): Promise<CarrierQuestionnaireResponse> {
  const response = await ApiService.getCarrierQuestionnaire(applicationId);
  return response.data;
}
